import styled from 'styled-components';

import { size } from '../layout/helpers';
import { theme } from '../layout/theme';

export const Box = styled.div`
  padding-left: ${size(3)};
  padding-right: ${size(3)};
  max-width: ${theme.distances.containerMaxWidth};
  margin: 0 auto;

  &.full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${theme.screens.Lg} {
      min-height: 900px;
    }
  }
`;