import styled from 'styled-components';

import { theme } from '../layout/theme';

export const Text = styled.div`
  &.small {
    font-size: 14px;
    
    ${theme.screens.Lg} {
      font-size: 18px;
    }
  }
  &.large {
    font-size: 18px;
  }

  ${theme.screens.Lg} {
    &.size-24-desktop {
      font-size: 24px;
    }
  }
`;
