import styled from 'styled-components';

import { size } from '../layout/helpers';
import { theme } from '../layout/theme';

export const Distance = styled.div`
  margin: ${size(2)} 0;

  &.size-half {
    margin: ${size(0.5)} 0;
  }

  &.size-0 {
    margin: 0;
  }

  &.size-1 {
    margin: ${size(1)} 0;
  }

  &.size-3 {
    margin: ${size(3)} 0;
  }

  &.size-4 {
    margin: ${size(4)} 0;
  }

  &.size-5 {
    margin: ${size(5)} 0;
  }

  &.size-6 {
    margin: ${size(6)} 0;
  }

  &.size-8 {
    margin: ${size(8)} 0;
  }

  &.size-10 {
    margin: ${size(10)} 0;
  }

  &.size-30 {
    margin: ${size(30)} 0;
  }

  ${theme.screens.LtLg} {
    &.size-half-ltmd {
      margin: ${size(0.5)} 0;
    }

    &.size-0-ltmd {
      margin: 0;
    }

    &.size-1-ltmd {
      margin: ${size(1)} 0;
    }

    &.size-3-ltmd {
      margin: ${size(3)} 0;
    }

    &.size-4-ltmd {
      margin: ${size(4)} 0;
    }

    &.size-5-ltmd {
      margin: ${size(5)} 0;
    }

    &.size-6-ltmd {
      margin: ${size(6)} 0;
    }
  }

  ${theme.screens.Lg} {
    &.size-half-md {
      margin: ${size(0.5)} 0;
    }

    &.size-0-md {
      margin: 0;
    }

    &.size-1-md {
      margin: ${size(1)} 0;
    }

    &.size-2-md {
      margin: ${size(2)} 0;
    }

    &.size-3-md {
      margin: ${size(3)} 0;
    }

    &.size-4-md {
      margin: ${size(4)} 0;
    }

    &.size-5-md {
      margin: ${size(5)} 0;
    }

    &.size-6-md {
      margin: ${size(6)} 0;
    }

    &.size-10-md {
      margin: ${size(10)} 0;
    }
  }
`;
