import React, { useEffect, useRef, useState } from 'react';

import { H1 } from '../../styled/Heading/wrappers';
import { Box } from '../../styled/Box/wrappers';
import { ContentMax } from '../../styled/ContentMax/wrappers';
import { Distance } from '../../styled/Distance/wrappers';
import {
  BoxLinks,
  LinkContainer,
  BoxLink,
  BoxText,
} from '../../styled/BoxLinks/wrappers';

import {
  Presentation,
  Head,
  Body,
} from '../../styled/Presentation/wrappers';
import Modal from '../../styled/Modal';
import data from './data.json';

const Roles = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeRole, setActiveRole] = useState(0);
  const [isIntersected, setIsIntersected] = useState(false);
  const boxesRef = useRef<HTMLDivElement | null>(null);

  const closeModal = () => {
    setShowModal(false);
  }

  const openModal = (role: number) => {
    setShowModal(true);
    setActiveRole(role);
  }

  const intersectCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (!isIntersected && entry.isIntersecting) {
        setIsIntersected(true);
      }
    });
  }

  const setupObserver = () => {
    let observer = new IntersectionObserver(intersectCallback, {
      rootMargin: '0px',
      threshold: 0.15
    });
    if (boxesRef.current) {
      observer.observe(boxesRef.current);
    }
  }

  useEffect(() => {
    setupObserver();
  }, []);

  return (
    <>
      <Box id="roles">
        <ContentMax>
          <H1 as="h2">Roller</H1>
          <p>
            Vi vill stärka vårt team med en mängd olika kompetenser och personligheter inom både utveckling och användarupplevelser.
          </p>
        </ContentMax>
        <Distance className="size-6" />
        <BoxLinks ref={boxesRef} className={isIntersected ? '' : 'not-intersected'} items={data.length}>
          <LinkContainer items={data.length}>
            {data.map((row, i) => (
              <BoxLink key={i} onClick={() => openModal(i)}>
                <BoxText>{row.name}</BoxText>
              </BoxLink>
            ))}
          </LinkContainer>
        </BoxLinks>
      </Box>
      {showModal && (
        <Modal onClose={closeModal}>
          <Presentation>
            <Head>{data[activeRole].name}</Head>
            <Body>
              <div dangerouslySetInnerHTML={{__html: data[activeRole].content}} />
            </Body>
          </Presentation>
        </Modal>
      )}
    </>
  );
};

export default Roles;
