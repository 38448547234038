import React from 'react';

import { GlobalStyle } from './styled/layout/global-style';
import { Distance } from './styled/Distance/wrappers';

import Header from './parts/header';
import Intro from './parts/intro';
import Roles from './parts/roles';
import Work from './parts/work';
import Office from './parts/office';
import About from './parts/about';
import Footer from './parts/footer';

function App() {
  return (
    <>
      <div className="App">
        <GlobalStyle />
        <Header />
        <Distance className="size-10" />
        <Intro />
        <Distance className="size-10" />
        <Roles />
        <Distance className="size-10" />
        <Work />
        <Distance className="size-10" />
        <Office />
        <Distance className="size-10" />
        <About />
        <Distance className="size-10" />
        <Footer />
      </div>
    </>
  );
}

export default App;
