const _colors = {
  white: '#fff',
  black: '#000',

  almostWhite: '#f6f6f6',
  almostBlack: '#191819',
};

const theme = {
  colors: {
    bg: {
      main: _colors.almostBlack,
      alt: _colors.black,
      pop: _colors.white,
    },
    text: {
      main: _colors.white,
      onPop: _colors.black,
    },
    button: {
      base: {
        default: _colors.white,
        hover: _colors.almostWhite,
        text: _colors.black,
      },
      inverted: {
        default: _colors.black,
        text: _colors.white,
      },
    },
  },
  font: {
    family: {
      main: '"Poppins", sans-serif',
    },
    lineHeight: 'calc(1em + .75rem)',
    letterSpacing: '-0.01em',
    weight: {
      regular: '400',
      medium: '500',
      bold: '600',
    },
  },
  layers: {
    panel: 100,
  },
  distances: {
    baseUnit: '8px',
    containerMaxWidth: '1160px',
    containerGutter: '16px',
    containerGutterMd: '32px',
    bodyMaxWidth: 'none',
    contentMaxWidth: '900px', // Used to limit width of text content
    inputHeight: '48px',
  },
  screens: {
    LtXS: '@media (max-width: 374px)',
    XS: '@media (min-width: 375px)',
    LtSm: '@media (max-width: 599px)',
    Sm: '@media (min-width: 600px)',
    SmLtMd: '@media (min-width: 600px) and (max-width: 899px)',
    LtMd: '@media (max-width: 899px)',
    Md: '@media (min-width: 900px)',
    MdLtLg: '@media (min-width: 900px) and (max-width: 1199px)',
    LtLg: '@media (max-width: 1199px)',
    Lg: '@media (min-width: 1200px)',
    LtXL: '@media (max-width: 1799px)',
    XL: '@media (min-width: 1800px)',
  },
};

export { theme };
