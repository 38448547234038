import styled from 'styled-components';

import { Button } from '../Button/wrappers';
import { theme } from '../layout/theme';
import { size } from '../layout/helpers';

export const StyledHeader = styled.div`
  display: flex;
  padding: ${size(6)} ${size(3)};
  justify-content: space-between;
  position: relative;
  margin: 0 auto;

  ${theme.screens.Lg} {
    padding: ${size(6)};
  }
`;

export const MobileMenuToggler = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;

  svg {
    margin-right: ${size(1)};
  }

  ${theme.screens.Lg} {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  svg {
    display: block;
    height: 60px;
    width: 134px;
  }

  ${theme.screens.LtLg} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      height: 32px;
    }
  }
`;

export const Menu = styled.div`
  ${theme.screens.LtLg} {
    .desktop-only {
      display: none;
    }
  }
  ${theme.screens.Lg} {
    display: flex;
    font-size: 24px;
    gap: ${size(7)};

    button {
      cursor: pointer;
      font-weight: ${theme.font.weight.medium};
    }
  }
`;

export const CTA = styled(Button)`
  ${theme.screens.LtLg} {
    height: ${size(4)};
    padding: 0 ${size(2)};
    font-size: 12px;

    .desktop {
      display: none;
    }
  }
  ${theme.screens.Lg} {
    font-size: 18px;
    .mobile {
      display: none;
    }
  }
`;

export const MobileMenu = styled.div`
  height: 100%;
  background: ${theme.colors.bg.pop};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${size(3)};
  color: ${theme.colors.text.onPop};
  padding: ${size(3)} ${size(5)};

  button {
    font-size: 32px;
    font-weight: ${theme.font.weight.medium};
  }

  ${CTA} {
    background: ${theme.colors.bg.alt};
    color: ${theme.colors.text.main};
    height: ${size(7)};
    padding: 0 ${size(5)};
    font-size: 16px;
    margin-top: ${size(3)};
  }
`;
