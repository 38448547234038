import styled, { css } from 'styled-components';

import { theme } from '../layout/theme';
import { size } from '../layout/helpers';

interface LinkContainerProps {
  items: number
}

export const LinkContainer = styled.div<LinkContainerProps>`
  ${theme.screens.LtLg} {
    columns: ${({ items }) => Math.ceil(items / 2)};
    width: ${({ items }) => Math.ceil(items / 2) * 295}px;
  }
  ${theme.screens.Lg} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${size(2)};
  }
`;

export const BoxText = styled.div`
  position: relative;
  z-index: 1;
  transition: color 150ms;
`;

export const BoxLink = styled.button`
  background: ${theme.colors.bg.pop};
  color: ${theme.colors.text.onPop};
  display: flex;
  align-items: flex-end;
  height: ${size(18)};
  padding: ${size(3)};
  width: ${size(35)};
  text-align: left;
  font-weight: ${theme.font.weight.medium};
  font-size: 18px;
  cursor: pointer;
  scroll-snap-align: start;
  transition: 1000ms ease-out;
  opacity: 1;

  &:before,
  &:after {
    content: "";
    background-image: radial-gradient(farthest-corner at 0% 35%, #E149CB 0%, #c44275 50%, #e43f1f 100%);
    background-size: 100% 200%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    opacity: 0;
    transition: 150ms;
    z-index: 0;
  }

  &:after {
    mix-blend-mode: lighten;
  }

  ${theme.screens.LtLg} {
    margin-bottom: ${size(2)};
  }

  ${theme.screens.Lg} {
    font-size: 24px;
    height: ${size(22)};
    width: 100%;

    &:hover {
      ${BoxText} {
        color: ${theme.colors.text.main};
      }

      &:before {
        opacity: 1;
      }
    }
  }
`;

const calcTransitionDelay = (size: number) => {
  let loopString = '';
  for (let i = 1; i < size + 1; i++) {
    loopString += `&:nth-child(${i}) {
        transition-delay: ${i * 100}ms;
      }
    `;
  }
  return loopString;
}

const calcTranslate = (size: number) => {
  let loopString = '';
  for (let i = 1; i < size + 1; i++) {
    loopString += `&:nth-child(${i}) {
        transform: translateY(${50 + (i * 5)}px) scale(0.98);
      }
    `;
  }
  return loopString;
}

interface BoxLinksProps {
  items: number
}

export const BoxLinks = styled.div<BoxLinksProps>`
  ${BoxLink} {
    ${({ items }) => items && calcTransitionDelay(items)};
  }

  ${theme.screens.LtLg} {
    overflow: auto;
    margin: 0 ${size(-3)};
    padding: 0 ${size(3)};
    scroll-snap-type: x mandatory;
    scroll-padding-left: ${size(3)};
  }

  &.not-intersected {
    ${BoxLink} {
      ${({ items }) => items && calcTranslate(items)};
      opacity: 0;
    }
  }
`;
