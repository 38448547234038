import React from 'react';

import { H1 } from '../../styled/Heading/wrappers';
import { Box } from '../../styled/Box/wrappers';
import { Text } from '../../styled/Text/wrappers';
import { BackgroundBlock, BackgroundBlockContent, BackgroundImage } from '../../styled/BackgroundBlock/wrappers';

const Office = () => (
  <BackgroundBlock>
    <BackgroundImage>
      <source media="(min-width: 900px)" srcSet="/assets/office-bg.jpg" />
      <img src="/assets/office-bg-mobile.jpg" alt="Skövde Science Park" />
    </BackgroundImage>
    <BackgroundBlockContent>
      <Box>
        <H1 as="h2" className="kill-br-desktop">Skövde <br /> science park</H1>
        <Text className="large size-24-desktop">
          Skövde Science Park är en mötesplats för människor och organisationer med fokus på utveckling, innovation och digitalisering. Med över 800 personer som arbetar i parken och fler än 80 olika företag är du dagligen omgiven av andra tech-aktörer och verksamheter som ligger i framkant inom sin nisch.
        </Text>
      </Box>
    </BackgroundBlockContent>
  </BackgroundBlock>
);

export default Office;
