import React from 'react';

import { TimesIcon } from '../Icon';
import {
  Modal as StyledModal,
  Close,
} from './wrappers';

interface IModal {
  children: React.ReactNode
  onClose: () => void
}

const Modal = ({ children, onClose }: IModal) => (
  <StyledModal>
    <Close onClick={onClose}>
      <TimesIcon />
    </Close>
    {children}
  </StyledModal>
)

export default Modal;
