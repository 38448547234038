import styled, { keyframes } from 'styled-components';

import { size } from '../layout/helpers';
import { theme } from '../layout/theme';

const hoverAnimation = keyframes`
  50% {
    background-size: 300% 150%;
  }
`;

export const Button = styled.button`
  border-radius: 50px;
  padding: 0 ${size(5)};
  height: ${size(7)};
  display: inline-block;
  display: inline-flex;
  align-items: center;
  gap: ${size(2)};
  background: ${theme.colors.button.base.default};
  color: ${theme.colors.button.base.text};
  font-weight: ${theme.font.weight.bold};
  position: relative;
  z-index: 1;
  transition: color 150ms;

  &:before {
    content: "";
    background-image: radial-gradient(farthest-corner at 0% 35%, #E149CB 0%, #c44275 50%, #e43f1f 100%);
    background-size: 100% 200%;
    background-position: center;
    border-radius: 50px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 150ms;
  }

  &:hover {
    color: ${theme.colors.text.main};

    &:before {
      opacity: 1;
      animation: ${hoverAnimation} 2s linear infinite;
    }
  }

  &.inverted {
    background: ${theme.colors.button.inverted.default};
    color: ${theme.colors.button.inverted.text};
  }

  ${theme.screens.Lg} {
    font-size: 18px;
  }
`;

export const TransparentButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: ${size(2)};
`;
