import styled from 'styled-components';

import { theme } from '../layout/theme';
import { size } from '../layout/helpers';

export const Presentation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${theme.screens.Lg} {
    flex-direction: row;
  }
`;

export const Head = styled.div`
  flex: 0 0 ${size(25)};
  background-color: #ba3666;
  background-image: url(/assets/color-rect.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  padding: ${size(4)} ${size(3)};
  font-size: 32px;
  font-weight: ${theme.font.weight.medium};

  ${theme.screens.Lg} {
    font-size: 56px;
    flex: 0 0 50%;
    align-items: center;
    padding: ${size(10)};
  }
`;

export const Body = styled.div`
  flex: 1 1 auto;
  background: ${theme.colors.bg.pop};
  color: ${theme.colors.text.onPop};
  padding: ${size(4)} ${size(5)};
  
  ${theme.screens.Lg} {
    flex: 0 0 50%;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${size(10)};
  }
`;
