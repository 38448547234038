import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';
import { size } from './helpers';

export const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  * {
    line-height: ${theme.font.lineHeight};
  }

  html {
    font-size: 16px;
    -webkit-text-size-adjust: 100%;
  }

  body {
  position: relative;
  margin: 0;
  font-family: ${theme.font.family.main};
  color: ${theme.colors.text.main};
  letter-spacing: ${theme.font.letterSpacing};
  max-width: ${theme.distances.bodyMaxWidth};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before,
body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

body::before {
  background: linear-gradient(45deg, rgba(217, 64, 194, 0.5) 0%, transparent 50%, rgba(53, 49, 151, 0.5) 100%);
  z-index: -1;
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: url(/assets/bg.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 25% 25%, 50% 50%, 75% 75%;
  z-index: -2;
}



  .App {
    position: relative;
  }

  input,
  textarea {
    -webkit-appearance: none;
  }

  a {
    background-color: transparent;
    color: currentColor;
    text-decoration: none;
  }

  a img {
    border: 0;
  }

  b,
  strong {
    font-weight: ${theme.font.weight.medium};
  }

  p {
    margin: 0 0 ${size(4)};
    max-width: 640px;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-size: inherit;
    line-height: 1.2;
    color: currentColor;
    font-family: ${theme.font.family.main};
    letter-spacing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: ${theme.font.weight.medium};
    margin: 0 0 ${size(3)};
  }

  h1 {
    font-size: 32px;
    line-height: 1.25;
    font-weight: ${theme.font.weight.bold};

    ${theme.screens.Lg} {
      font-size: 40px;
      line-height: 1.2;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 1.333;

    ${theme.screens.Lg} {
      font-size: 32px;
      line-height: 1.25;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1.2;

    ${theme.screens.Lg} {
      font-size: 24px;
      line-height: 1.333;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 1.5;

    ${theme.screens.Lg} {
      font-size: 20px;
      line-height: 1.4;
    }
  }

  ${theme.screens.LtLg} {
    .hidden-lt-md {
      display: none;
    }
  }

  ${theme.screens.Lg} {
    .hidden-md {
      display: none;
    }
  }
`;
