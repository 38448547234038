import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { theme } from '../layout/theme';
import { size } from '../layout/helpers';

interface IStyledIcon {
  className?: string
  strokeWidth?: string
}

const BaseSvg = styled.svg`
  display: block;
  width: 1em;
  height: 1em;
  overflow: visible;
  fill: none;
  font-size: 24px;

  &.inline {
    display: inline;
  }

  &.large {
    font-size: 20px;
  }
  &.xlarge {
    font-size: 24px;
  }
  &.xxlarge {
    font-size: 32px;
  }
  &.bump-down {
    margin-top: 2px;
  }
`;

const StrokeSvg = styled(BaseSvg)`
  stroke: currentColor;
`;
const FillSvg = styled(BaseSvg)`
  fill: currentColor;
`;

export const ChevronDownIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 24 24" className={className}>
    <path d="m6 9 6 6 6-6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const ChevronLeftIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 24 24" className={className}>
    <path d="M15 18l-6-6 6-6" stroke="currentColor" strokeWidth={strokeWidth || 1} strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const ChevronRightIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 24 24" className={className}>
    <path d="M9 18l6-6-6-6" stroke="currentColor" strokeWidth={strokeWidth || 1} strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const ChevronUpIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 24 24" className={className}>
    <path d="M18 15l-6-6-6 6" stroke="currentColor" strokeWidth={strokeWidth || 1} strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const TimesIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 24 24" className={className}>
    <path d="M18 6 6 18M6 6l12 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const PercentIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <FillSvg viewBox="0 0 24 25" className={className}>
      <path d="M.386 5.751c0-1.606.462-2.86 1.386-3.762.946-.924 2.156-1.386002 3.63-1.386002 1.474 0 2.673.462002 3.597 1.386002.946.902 1.419 2.156 1.419 3.762 0 1.606-.473 2.871-1.419 3.795-.924.924-2.123 1.386-3.597 1.386-1.474 0-2.684-.462-3.63-1.386C.848 8.622.386 7.357.386 5.751Zm20.13-4.719L7.349 24H3.653L16.82 1.032h3.696ZM5.402 2.88c-1.386 0-2.079.957-2.079 2.871 0 1.936.693 2.904 2.079 2.904.66 0 1.166-.231 1.518-.693.374-.484.561-1.221.561-2.211 0-1.914-.693-2.871-2.079-2.871Zm8.415 16.368c0-1.606.462-2.86 1.386-3.762.946-.924 2.156-1.386 3.63-1.386 1.452 0 2.64.462 3.564 1.386.946.902 1.419 2.156 1.419 3.762 0 1.606-.473 2.871-1.419 3.795-.924.924-2.112 1.386-3.564 1.386-1.474 0-2.684-.462-3.63-1.386-.924-.924-1.386-2.189-1.386-3.795Zm4.983-2.871c-1.386 0-2.079.957-2.079 2.871 0 1.914.693 2.871 2.079 2.871 1.386 0 2.079-.957 2.079-2.871 0-1.914-.693-2.871-2.079-2.871Z" />
  </FillSvg>
);

export const SmileIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
      <path d="M16 29.3334c7.3638 0 13.3333-5.9695 13.3333-13.3333 0-7.36382-5.9695-13.33335-13.3333-13.33335-7.36384 0-13.33337 5.96953-13.33337 13.33335 0 7.3638 5.96953 13.3333 13.33337 13.3333Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.6666 18.6667s2 2.6667 5.3334 2.6667c3.3333 0 5.3333-2.6667 5.3333-2.6667M12 12h.0133M20 12h.0133" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const DumbellIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M9.33325 16H22.6666M3.99992 16H1.33325M30.6667 16H28M9.33333 9.33341c0-1.47275-1.1939-2.66666-2.66666-2.66666C5.19391 6.66675 4 7.86066 4 9.33341V22.6667c0 1.4728 1.19391 2.6667 2.66667 2.6667 1.47276 0 2.66666-1.1939 2.66666-2.6667V9.33341ZM22.6667 22.6668c0 1.4728 1.1939 2.6667 2.6666 2.6667 1.4728 0 2.6667-1.1939 2.6667-2.6667V9.3335c0-1.47276-1.1939-2.66667-2.6667-2.66667-1.4727 0-2.6666 1.19391-2.6666 2.66667v13.3333Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
  </StrokeSvg>
);

export const HatIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M30.6667 14.6667v6.6667M16.0001 17.3333 2.66675 10.6667 16.0001 4l13.3333 6.6667-13.3333 6.6666Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M6.66675 12.6667v11.3334c0 2.2093 4.17865 4 9.33335 4 5.1546 0 9.3333-1.7907 9.3333-4V12.6667" strokeWidth="2" strokeMiterlimit="10" />
  </StrokeSvg>
);

export const BurgerIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M4 24v1.3333c0 2.2094 1.79067 4 4 4h16c2.2093 0 4-1.7906 4-4V24M4 12.0001c0-5.15469 4.17867-9.33335 9.3333-9.33335h5.3334C23.8213 2.66675 28 6.84541 28 12.0001" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M17.3334 17.3333H4.00004c-1.46667 0-2.66667-1.2-2.66667-2.6666 0-1.4667 1.2-2.6667 2.66667-2.6667H28c1.4667 0 2.6667 1.2 2.6667 2.6667 0 1.4666-1.2 2.6666-2.6667 2.6666h-2.6666l-4 4-4-4Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M4.66671 17.3335c-1.86667 0-3.33334 1.4667-3.33334 3.3333 0 1.8667 1.46667 3.3334 3.33334 3.3334H27.3334c1.8666 0 3.3333-1.4667 3.3333-3.3334 0-1.8666-1.4667-3.3333-3.3333-3.3333" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
  </StrokeSvg>
);

export const CouchIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M25 18H7c-1.10457 0-2-.8954-2-2v-1c0-1.1046-.89543-2-2-2s-2 .8954-2 2v5c0 1.1046.89543 2 2 2h26c1.1046 0 2-.8954 2-2v-5c0-1.1046-.8954-2-2-2s-2 .8954-2 2v1c0 1.1046-.8954 2-2 2Z" strokeWidth="2" />
    <path strokeWidth="2" strokeLinecap="round" d="M26 22.9258v.96296M6 22.9258v.9629" />
    <path d="M14 10H7c-1.10457 0-2 .8954-2 2v4c0 1.1046.89543 2 2 2h7c1.1046 0 2-.8954 2-2v-4c0-1.1046-.8954-2-2-2ZM18 10h7c1.1046 0 2 .8954 2 2v4c0 1.1046-.8954 2-2 2h-7c-1.1046 0-2-.8954-2-2v-4c0-1.1046.8954-2 2-2Z" strokeWidth="2" />
  </StrokeSvg>
);

export const ControllerIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M24.182 14H7.818C4.053 14 1 17.134 1 21s3.053 7 6.818 7c2.794 0 5.129-1.53 6.182-4h4c1.053 2.47 3.387 4 6.182 4C27.947 28 31 24.866 31 21s-3.053-7-6.818-7ZM8 19v4M6 21h4M16 10V2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M26 21c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1ZM23 24c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1Z" fill="currentColor" stroke="none" />
  </StrokeSvg>
);

export const CarIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M22 22H10M4 22H3c-1.10457 0-2-.8954-2-2v-5.3591c0-.4168.13025-.8232.37253-1.1624l4.02924-5.64098C5.77719 7.31193 6.38333 7 7.02924 7H18.8394c.7177 0 1.3804.38457 1.7364 1.00772l2.8484 4.98458c.356.6231 1.0187 1.0077 1.7364 1.0077H29c1.1046 0 2 .8954 2 2v4c0 1.1046-.8954 2-2 2h-1" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M7 25c1.65685 0 3-1.3431 3-3s-1.34315-3-3-3-3 1.3431-3 3 1.34315 3 3 3ZM25 25c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M1 14h23M13 7v7" strokeWidth="2" strokeMiterlimit="10" />
  </StrokeSvg>
);

export const MeditationIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 32 32" className={className}>
    <path d="M16 8c1.6569 0 3-1.34315 3-3s-1.3431-3-3-3-3 1.34315-3 3 1.3431 3 3 3ZM7 25h18M1 29h30M28 13l-2.392 1.594c-.1765.1177-.3859.1761-.5978.167-.212-.0092-.4155-.0855-.5812-.218L20 11h-8l-4.429 3.543c-.1657.1325-.36924.2088-.58118.218-.21193.0091-.4213-.0493-.59782-.167L4 13M12 11v11M20 11v11" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  </StrokeSvg>
);

export const MenuIcon = ({ className, strokeWidth }: IStyledIcon): ReactElement => (
  <StrokeSvg viewBox="0 0 24 24" className={className}>
    <path d="M3 12h18M3 6h18M3 18h18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </StrokeSvg>
);

export const IconBall = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.bg.alt};
  width: ${size(6)};
  height: ${size(6)};
`;

export const ColoredIconBall = styled(IconBall)`
  background-image: url(/assets/color-rect.jpg);
  background-size: cover;
  background-position: center;
  width: ${size(10)};
  height: ${size(10)};
  
  svg {
    font-size: 32px;
  }

  &.blue {
     background-image: linear-gradient(133.56deg, #00E0FF 11.81%, #26A4FF 28.48%, #263CFF 53.29%, #7331FF 69.95%, #8626FF 86.23%);
  }
`;