import styled from 'styled-components';

import { theme } from '../layout/theme';

export const H1 = styled.h1`
  font-size: 32px;

  &.extra-extra {
    ${theme.screens.Lg} {
      font-size: 64px;
    }
  }
  
  ${theme.screens.Lg} {
    font-size: 48px;

    &.kill-br-desktop {
      br {
        display: none;
      }
    }
  }
`;

export const H2 = styled.h2`
  font-size: 24px;
  
  ${theme.screens.Lg} {
    font-size: 32px;
  }
`;

export const H3 = styled.h3`
  font-size: 20px;
  
  ${theme.screens.Lg} {
    font-size: 24px;
  }
`;

export const H4 = styled.h4`
  font-size: 18px;
  
  ${theme.screens.Lg} {
    font-size: 20px;
  }
`;

export const H5 = styled.h4`
  font-size: 16px;
  
  ${theme.screens.Lg} {
    font-size: 18px;
  }
`;
