import React, { useState } from 'react';

import { H1 } from '../../styled/Heading/wrappers';
import { Box } from '../../styled/Box/wrappers';
import { Distance } from '../../styled/Distance/wrappers';
import { ContentMax } from '../../styled/ContentMax/wrappers';

import Benefits from './Benefits';

import {
  Columns,
  LeftColumn,
  RightColumn,
  TabList,
  Tab,
} from './wrappers';

const Work = () => {
  const [activeTab, setActiveTab] = useState('team');

  return (
    <Box id="work">
      <ContentMax>
        <H1 as="h2">Att jobba på mio</H1>
        <Distance className="size-1 size-10-md" />
        <Columns>
          <LeftColumn>
          <TabList>
            <Tab className={activeTab === 'team' ? 'active' : ''} onClick={() => setActiveTab('team')}>Teamet</Tab>
            <Tab className={activeTab === 'challange' ? 'active' : ''} onClick={() => setActiveTab('challange')}>Utmaningen</Tab>
            <Tab className={activeTab === 'benefits' ? 'active' : ''} onClick={() => setActiveTab('benefits')}>Förmånerna</Tab>
          </TabList>
          </LeftColumn>
          <RightColumn>
            {activeTab === 'team' && (
              <>
                <p>Mio är en arbetsplats där man vill utvecklas och ta egna initiativ – med stöttning, tydligt ledarskap och en stark sammanhållning. En familjär arbetsplats helt enkelt. Vi tror att det är under de förutsättningarna man uppnår bäst resultat – och har roligt medan man gör det.</p>
                <p>Den 1 februari 2022 öppnade Mio AB ett tech-kontor i Skövde Science Park och söker nu fler medarbetare med olika bakgrunder och kompetenser. Vår grupp ansvarar för att utveckla och underhålla system kring webb och e-handel. Vi designar, bygger och vidareutvecklar våra egna system för att de ska passa vår verksamhet. Vi tror på att anpassa systemen efter verksamheten och inte anpassa verksamheten efter systemen.</p>
                <p>Vi är idag ett litet team på tolv personer där du kommer att göra skillnad. Din insats påverkar resultatet vi levererar och i teamet väljer vi tillsammans arbetssätt för att nå dit. Vi ligger i framkant med moderna tekniska lösningar och eftersträvar en hög grad av automation. För att fortsätta bli bättre än vad vi var igår behöver vi ett team som jobbar sammansvetsat och målinriktat. Precis som oss ser vi att du inte är inte rädd för att utmana eller förnya dig själv.</p>
                <p>Vill du vara med när Sveriges största och snabbast växande e-handelsaktör inom möbler och heminredning bonar om Sverige? Klart du vill!</p>
              </>
            )}
            {activeTab === 'challange' && (
              <>
                <p>Att sälja möbler och inredning kan verka enkelt, men det finns många utmaningar som är mycket mer komplexa än vad man kan tro. Från hur man hanterar all data kopplat till konfiguration av en soffa med miljontals kombinationer och bilder, till hur man bäst säljer en lampa eller logiskt hanterar kampanjer och rabatter. Det är här du kommer in i bilden.</p>
                <p>När man expanderar fort gäller det att man inte tappar kvalitén eller fokuset på det lilla – det är det som gör skillnad i det stora. På Mio.se säljer vi årligen för över 1 miljard SEK och har över 50 miljoner besökare. Vi behöver fler personer till vårt team som med just sin spetskompetens bidrar till en kvalitativ och trygg kundupplevelse.</p>
              </>
            )}
            {activeTab === 'benefits' && (
              <Benefits />
            )}
          </RightColumn>
        </Columns>
      </ContentMax>
    </Box>
  );
};
export default Work;
