import styled from 'styled-components';

import { theme } from '../layout/theme';
import { size } from '../layout/helpers';

export const BackgroundBlock = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${theme.screens.Lg} {
    min-height: 900px;
  }
`;

export const BackgroundImage = styled.picture`
  img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BackgroundBlockContent = styled.div`
  position: relative;
  z-index: 2;

  ${theme.screens.Lg} {
    width: ${size(80)};
    margin-left: 10vw;
  }
`;