import styled from 'styled-components';

import { theme } from '../../styled/layout/theme';
import { size } from '../../styled/layout/helpers';

export const Columns = styled.div`
  ${theme.screens.Lg} {
    display: flex;
  }
`;

export const LeftColumn = styled.div`
  ${theme.screens.Lg} {
    flex: 0 0 250px;
  }
`;

export const RightColumn = styled.div`
  font-size: 14px;
  line-height: 1.7;

  ${theme.screens.Lg} {
    flex: 1 1 auto;
    font-size: 18px;
  }
`;

export const TabList = styled.div`
  ${theme.screens.LtXS} {
    gap: ${size(2)};
  }
  ${theme.screens.XS} {
    gap: ${size(3)};
  }
  ${theme.screens.LtLg} {
    margin-bottom: ${size(3)};
    display: flex;
  }
  ${theme.screens.Lg} {
    flex-direction: column;
  }
`;

export const Tab = styled.button`
  font-weight: ${theme.font.weight.medium};
  letter-spacing: 0.01em;
  cursor: pointer;

  &.active {
    &:before {
      content: "";
      position: absolute;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(farthest-corner at 0% 35%,#E149CB 0%,#c44275 50%,#e43f1f 100%);
      background-size: 100% 200%;
      mix-blend-mode: darken;
    }
  }

  ${theme.screens.LtLg} {
    padding-bottom: ${size(1)};

    &.active {
      &:before {
        border-bottom: 2px solid;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  ${theme.screens.Lg} {
    font-size: 24px;
    display: block;

    & + & {
      margin-top: ${size(3)};
    }

    &.active {
      &:before {
        content: "";
        left: ${size(-4)};
        top: calc(50% - ${size(1)});
        width: ${size(2)};
        height: ${size(2)};
        border-radius: 4px;
        background-image: radial-gradient(farthest-corner at 0% 100%, #fe7ef2 0%, #fb4343 100%);
      }
    }
  }
`;