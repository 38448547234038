import styled from 'styled-components';

import { size } from '../layout/helpers';
import { theme } from '../layout/theme';

export const FooterBox = styled.div`
  background-image: radial-gradient(farthest-corner at 50% 90%,#eac35a,transparent),linear-gradient(4deg,#b66e24,transparent),linear-gradient(105deg,#9a0d51,#320c86);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  position: relative;
  padding: ${size(4)};

  ${theme.screens.Lg} {
    padding: ${size(6)};
    min-height: 70vh;
  }
`;

export const FooterText = styled.div`
  font-weight: ${theme.font.weight.bold};
  font-size: 24px;
  margin: ${size(4)} 0;
  max-width: ${size(100)};

  ${theme.screens.Lg} {
    font-weight: ${theme.font.weight.medium};
    font-size: 48px;
    margin: ${size(4)} ${size(5)};
  }
`;

export const FooterMain = styled.div`
  ${theme.screens.Lg} {
    svg {
      width: 134px;
      height: 60px;
    }
  }
`;

export const FooterSecondary = styled.div`
  position: absolute;
  bottom: ${size(10)};
  display: flex;
  gap: ${size(5)};

  ${theme.screens.Lg} {
    gap: ${size(6)}
  }
`;