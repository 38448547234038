import React from 'react';

import { Box } from '../../styled/Box/wrappers';
import { ContentMax } from '../../styled/ContentMax/wrappers';
import { Text } from '../../styled/Text/wrappers';
import { H1 } from '../../styled/Heading/wrappers';

const About = () => (
  <Box className="full-height" id="about">
    <ContentMax>
      <H1 as="h2">Vi möblerar och inreder Sverige!</H1>
      <Text className="small">
        <p>
          Mio ska vara Sveriges destination för möbler och inredning. Mio ska hjälpa alla att skapa ett hem att trivas i och vara stolta över. Gränserna mellan kanalerna i kundmötet suddas ut och vi ska finnas där kunderna önskar på deras villkor – det ska kännas som ett Mio. Våra kunder ska känna sig välkomna, inspirerade och trygga. Vi möblerar och inreder Sverige!
        </p>
        <p>
          Mio är en ledande svensk detaljhandelskedja för möbler och heminredning som i nära 60 år har levererat inredningslösningar för hemmet. Vi finns med butiker på över 70 platser i Sverige samt på nätet med e-handel. Mio ägs av handlarna som driver sina butiker framåt utifrån Mios koncept och den lokala marknadens behov. Grunden är en stark och positiv entreprenörskultur.
        </p>
        <p>
          Vi brinner för att förgylla kundernas liv hemma med prisvärda möbler av god kvalitet. Vi vill vara det spännande alternativet som inspirerar kunderna att förnya sina hem.
        </p>
      </Text>
    </ContentMax>
  </Box>
);

export default About;
