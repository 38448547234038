import React from 'react';
import styled from 'styled-components';

import { H5 } from '../../styled/Heading/wrappers';

import { Distance } from '../../styled/Distance/wrappers';
import { theme } from '../../styled/layout/theme';
import { size } from '../../styled/layout/helpers';
import {
  ColoredIconBall,
  PercentIcon,
  SmileIcon,
  DumbellIcon,
  HatIcon,
  BurgerIcon,
  ControllerIcon,
  CarIcon,
} from '../../styled/Icon';

const Columns = styled.div`
  display: flex;
  gap: ${size(4)};
  flex-wrap: wrap;
  font-size: 16px;
`;

const Column = styled.div`
  ${theme.screens.Lg} {
    flex: 0 0 calc(50% - ${size(2)});
  }
`;

const Benefits = () => (
  <Columns>
    <Column>
      <ColoredIconBall>
        <PercentIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Personalrabatt</H5>
      <p>Vi tycker att våra medarbetare ska få uppleva Mios goda kvalitet själva – till ett riktigt bra pris. Därför får du som anställd en förmånlig personalrabatt vid köp av möbler och heminredning från Mio.</p>
    </Column>
    <Column>
      <ColoredIconBall>
        <SmileIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Friskvårdsbidrag</H5>
      <p>Återhämtning är minst lika viktigt som att utmanas. Unna dig en massage eller något annat du tycker är kul för att boostas av endorfiner. Vi värdesätter våra medarbetares hälsa och som anställd hos Mio har du tillgång till ett generöst friskvårdsbidrag.</p>
    </Column>
    <Column>
      <ColoredIconBall>
        <DumbellIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Gymkort</H5>
      <p>Utöver friskvårdsbidrag får du som medarbetare tillgång till företagsparkens gym där du som anställd kan teckna ett årskort för endast 600 kr.</p>
    </Column>
    <Column>
      <ColoredIconBall>
        <HatIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Vidareutbildning</H5>
      <p>Vi förstår vikten av att inte stå still och vill att vårt team ska utvecklas. Därför uppmuntrar vi till vidareutbildning och vi gör tid för dig som anställd att nå dina mål.</p>
    </Column>
    <Column>
      <ColoredIconBall className="blue">
        <BurgerIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Events och firande</H5>
      <p>Vi håller regelbundet i evenemang som t.ex. kunskapsluncher där mat ingår och där du som anställd får möjlighet att ta del av viktiga projekt eller en ny teknik som kan gynna dig i ditt arbete. Vi tycker det är viktigt att fira framgångar och anordnar ofta företagsfester. Vi tilldelar årligen ett belopp på 1200kr per anställd till varje avdelning som de tillsammans kan göra något kul för.</p>
    </Column>
    <Column>
      <ColoredIconBall className="blue">
        <ControllerIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Sociala ytor</H5>
      <p>I företagsparken finns det tränings- och relaxutrymmen med bland annat bastu. Det finns även restauranger och kiosker som serverar både frukost och dagens lunch. Våra kontor är inredda med Mio-möbler och det finns utrustning för att man ska vilja hänga här även efter att jobbdagen är slut – exempelvis ett hörn med PS5. </p>
    </Column>
    <Column>
      <ColoredIconBall className="blue">
        <CarIcon />
      </ColoredIconBall>
      <Distance className="size-3" />
      <H5>Poolbil och släp</H5>
      <p>Företagsparken har en poolbil som man kan nyttja till ett mycket förmånligt pris både under kortare och längre perioder. Det går även boka ett släp.</p>
    </Column>
  </Columns>
);

export default Benefits;
