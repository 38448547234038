import React from 'react';

import { H1 } from '../../styled/Heading/wrappers';
import { Box } from '../../styled/Box/wrappers';
import { ContentMax } from '../../styled/ContentMax/wrappers';
import { Text } from '../../styled/Text/wrappers';
import { Button, TransparentButton } from '../../styled/Button/wrappers';
import { Distance } from '../../styled/Distance/wrappers';
import { IconBall, ChevronDownIcon } from '../../styled/Icon';

const Intro = () => {
  const goToRoles = () => {
    const rolesEl = document.getElementById('roles');
    if (rolesEl) {
      rolesEl.scrollIntoView({
        behavior: "smooth",
        block: 'start'
      });
    }
  }

  return (
    <>
      <Box>
        <ContentMax>
          <H1 className="extra-extra">Lös svåra utmaningar tillsammans med kunniga kollegor.</H1>
          <Distance className="size-2 size-5-md" />
          <Text className="size-24-desktop">
            <p>Arbeta hos en av Sveriges största och snabbast växande e-handelsaktörer inom möbler och heminredning.</p>
          </Text>
          <Distance className="size-2 size-5-md" />
          <Button as="a" href="https://mio.varbi.com/se/what:spontaneous/jobID:3019/type:spontaneous/where:4/apply:1/" target="_blank">Ansök</Button>
          <Distance className="size-10" />
          <Distance className="size-30 hidden-lt-md" />
          <div className="hidden-md">
            <TransparentButton onClick={goToRoles}>
              <IconBall>
                <ChevronDownIcon />
              </IconBall>
              Se roller
            </TransparentButton>
          </div>
        </ContentMax>
      </Box>
    </>
  );
};

export default Intro;
