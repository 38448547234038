import styled from 'styled-components';

import { theme } from '../layout/theme';
import { size } from '../layout/helpers';

export const Modal = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Close = styled.button`
  border-radius: 50%;
  width: ${size(7)};
  height: ${size(7)};
  background: ${theme.colors.bg.alt};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-index: 120;
  top: ${size(4)};
  right: ${size(3)};
  cursor: pointer;
`;
